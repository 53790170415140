.breadcrumb-container .breadcrumb {
  padding: 34px 0;
  color: #19191a;
  font-size: .7rem;
  .breadcrumb-item{
    a {
      color: $primary;
      font-weight: 600;
    }
    &.active a {
      color: #19191a;
    }
  }
}
