.pagination .page-item {
  .page-link {
    color: $primary;
    background-color: transparent;
    .icon {
      fill: $primary;
    }
    &:hover {
      color: $green;
      .icon {
        fill: $green;
      }
    }
    &[aria-current] {
      border: 1px solid $green;
      color: $green;
      pointer-events: none;
    }
  }
}
