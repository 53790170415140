.it-header-center-wrapper {
  &.it-small-header {
    height: auto;
    margin: 0;
    padding: 10px 0;
  }
}

.it-header-wrapper {
  .btn-social {
    -moz-appearance: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: $text-color-grey;
  }
  .it-nav-wrapper {
    background-color: $white;
    position: relative;
    // if it has menu
    .it-brand-wrapper {
      padding-left: $v-gap*3;
    }
    .it-header-navbar-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 40px;
      margin: auto;
      nav {
        padding-left: 0;
        padding-right: 0;
        .custom-navbar-toggler {
          padding: 0;
          transform: translateX(-2px);
          cursor: pointer;
          .icon {
            fill: $italia;
          }
        }
      }
    }
  }
}

.it-brand-wrapper {
  display: flex;
  justify-content: space-between;
  .logo img {
    width: 26vw;
    height: auto;
    margin-left: 1em;
  }
}

.it-header-slim-wrapper {
  background-color: $secondary-color;
  color: $white;
  min-height: 50px;
  padding-top: 10px;
  &-content {
    color: $white;
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    width: 100%;
    a.link, 
    a {
      margin: 0 0.5em;
      color: $white;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      text-decoration: none;
      font-size: .75em;

      &:hover {
       text-decoration: underline;
      }
    }
    .link-list {
      list-style-type: none;
      margin: 0 0.3em;
      padding-left: 0;
      li {
        display: inline;
        color: $white;
        border-right: 1px solid $white;
        &:last-child {
          border-right: none;
        }
        a {
          color: $white;
          font-weight: 600;
          margin: 0 0.3em;
          &:hover {
            color: $green;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.it-header-center-content-wrapper {
  padding: 0;
  .it-search-wrapper .rounded-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-color: transparent;
    display: block;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    transition: 0.3s;
    .icon {
      fill: $primary;
    }
  }
}

.logotxt {
  display: none;
}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {

  .it-brand-wrapper {
    display: flex;
    justify-content: space-between;
    .logo img {
      width: 11vw;
      height: auto;
      max-width: 350px;
      margin-left: 0;
    }
  }
}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {

  .it-header-center-wrapper {
    .it-header-center-content-wrapper {
      padding-left: 0;

      .it-search-wrapper {
        margin-left: 0 !important;
      }
    }
  }

  .it-header-navbar-wrapper nav .navbar-collapsable .menu-wrapper {
    align-items: center;
  }
  
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {

  .it-header-wrapper {
    .it-nav-wrapper {
      background-color: $white;
      height: auto;
      overflow: initial;
      transition: height 0.25s;
      position: relative;
      .it-header-navbar-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        bottom: initial;
        height: auto;
        margin: auto;
        margin-bottom: 0;
        background-color: $white;
      }
      .it-brand-wrapper {
        padding-left: 0;
        .logo img {
          width: 120px;
          max-width: 120px;
        }
      }
      .it-header-navbar-wrapper {
        position: inherit;
        left: auto;
        top: inherit;
        margin-top: 0;
        nav {
          padding-left: $header-general-padding - ($grid-columns/2);
          padding-right: $header-general-padding - ($grid-columns/2);
        }
      }
      .logo img {
        width: 50vw;
        height: auto;
        margin-left: 0;
      }
      &.resize {
        overflow: initial;
        box-shadow: 0 2px 28px 0 rgba(0,0,0,.06);
        top: -30px;
        height: 72px;

        .it-header-center-wrapper {
          &.it-small-header {
            height: 90px;
          }
        }
        .logo img {
          width: 100px;
          max-width: 100px;
        }
      }
    }
  }

  .it-header-slim-wrapper {
    background-color: $secondary-color;
    &-content {
      font-size: 16px;
      a.link {
        margin: 0 0.5em;
      }
      .link-list {
        margin: 0 0.5em;
        li a {
          color: $white;
          font-weight: 600;
          padding: 0 0.5em;
        }
      }
    }
  }

  .resize {
    .it-header-center-content-wrapper {
      animation: fadeIn .5s ease, margin-top .5s ease, margin-bottom .5s ease;
      margin-top: 0;
    }
    
    .logotxt {
      font-size: 1.2vw;
    }
  }
}


//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {
  .it-header-slim-wrapper {
    min-height: auto;
    padding-top: 0;
  }
}



@media (min-width: 1440px) {
  .it-header-wrapper .it-nav-wrapper .it-brand-wrapper .logo img {
    width: 150px;
    max-width: 150px;
  }
}



