#searchModal {
  z-index: 1100;
  padding: 0;
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: 100%;
  }
  .modal-content {
    height: 100%;
    overflow-y: auto;
  }
  .modal-body-search {
    margin-bottom: 1em;
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal-header-fullsrc {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .fas.fa-times {
    color: $primary;
  }

  #cerca-txt,
  .search-filter-dategroup .form-group input {
    font-weight: 300;
    color: #9b9b9b;
    height: 56px;
    border: 0;
    border-radius: 4px;
    background-color: $light-grey;
    padding: 16px 24px;
    font-size: 0.889em;
  }
  input {
    &::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
      color: #9b9b9b;
      font-size: 0.889em;
      line-height: 21px;
    }
  }

  .form-group {
    padding: 0;
    .ico-sufix {
      position: absolute;
      width: 56px;
      text-align: center;
      right: 0;
      top: 0;
      bottom: 0;
      vertical-align: middle;
      background-color: $green;
      color: $white;
      border-radius: 0 4px 4px 0;
      border: 0;
      padding: 0;
      svg.icon {
        width: 26px;
        height: 26px;
        fill: $white;
      }
    }
  }

  .form-suggerimenti ul {
    list-style-type: none;
    padding-left: 0;
    a {
      font-weight: 600;
    }
  }

  #searchclear {
    position: absolute;
    top: 12px;
    background: transparent;
    right: 64px;
    -webkit-appearance: none;
    border: 0;
    color: #661224;
    padding: 0;
    line-height: 0;
    outline: none;
    svg.icon {
      width: 32px;
      height: 32px;
      fill: #661224;
    }
  }
}
