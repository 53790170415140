body:not(.template-edit) {
  label {
    color: $input-label-color;
  }

  .form-group {
    position: relative;
    margin-bottom: $form-group-margin-bottom;
    margin-top: $form-group-margin-top;

    input,
    optgroup,
    textarea {
      color: $dark;
    }

    label {
      background-color: transparent;
      position: absolute;
      font-weight: 600;
      line-height: calc(#{$input-height} - 1px);
      transition: 0.2s ease-out;
      top: 0;
      font-size: 1rem;
      cursor: text;
      display: block;
      max-width: 100%;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 $input-spacing-x;
      // z-index: 6;

      &.active {
        transform: translateY(-75%);
        font-size: $small-font-size;
      }
    }

    small.form-text {
      position: absolute;
      margin: 0;
      padding: $input-spacing-x/2 $input-spacing-x;
      font-size: $small-font-size;
    }

    input[type='time']~label {
      transform: translateY(-75%);
      font-size: $small-font-size;
    }
  }

  ::placeholder {
    font-weight: normal;
  }

  input[type='date'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  textarea {
    border: none;
    border-bottom: 1px solid $gray-secondary;
    border-radius: 0;
    padding: $input-spacing-y $input-spacing-x;
    outline: 0;
    height: $input-height;
    width: 100%;
    box-shadow: none;
    transition: 0.2s ease-out;
    font-weight: 700;
  }

  textarea {
    border: 1px solid $gray-secondary;
    height: auto;
  }

  .form-control {

    &:disabled,
    &[readonly] {
      cursor: not-allowed;

      &+label {
        background-color: transparent;
        cursor: not-allowed;
      }
    }
  }

  .form-group.active .form-file-name {
    padding-bottom: 1.95rem;
  }

  .form-control-plaintext {
    padding: $input-spacing-y $input-spacing-x;
    background-color: $white !important;
    cursor: not-allowed;

    &+label {
      cursor: not-allowed;
    }
  }

  .form-control {
    background-position: center right !important;
    background-repeat: no-repeat !important;
    background-size: 45px 45% !important;

    &:focus,
    &:active {
      box-shadow: none !important;
    }

    .was-validated &:valid,
    &.is-valid {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300cc85' viewBox='0 0 192 512'%3E%3Cpath d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
    }

    .was-validated &:invalid,
    &.is-invalid {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f73e5a' viewBox='0 0 384 512'%3E%3Cpath d='M231.6 256l130.1-130.1c4.7-4.7 4.7-12.3 0-17l-22.6-22.6c-4.7-4.7-12.3-4.7-17 0L192 216.4 61.9 86.3c-4.7-4.7-12.3-4.7-17 0l-22.6 22.6c-4.7 4.7-4.7 12.3 0 17L152.4 256 22.3 386.1c-4.7 4.7-4.7 12.3 0 17l22.6 22.6c4.7 4.7 12.3 4.7 17 0L192 295.6l130.1 130.1c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17L231.6 256z'/%3E%3C/svg%3E");
    }

    &.warning {
      background-size: 25px 45% !important;
      border-color: $warning;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff9900' viewBox='0 0 192 512'%3E%3Cpath d='M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z'/%3E%3C/svg%3E");
    }

    &.is-valid~.warning-feedback {
      display: block;
    }
  }

  .warning-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.777rem;
    color: $warning;
  }

  .valid-feedback,
  .invalid-feedback,
  .warning-feedback {
    margin-left: 0.5rem;
  }

  // Input group
  .input-group {
    .input-group-text {
      padding: $input-spacing-y $input-spacing-x;
      border-bottom: 1px solid $gray-secondary;
    }

    .input-group-prepend {
      margin-right: 0;

      &~label {
        left: 2.25rem;
        max-width: calc(100% - 2.25rem);
      }

      .btn {
        border-radius: 4px 0 0 4px;
      }
    }

    .input-group-append {
      margin-left: 0;

      .btn {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 1px solid #5c6f82;
        border-radius: 0 4px 4px 0;
        box-shadow: none;
      }
    }
  }

  .input-group-lg {

    &>.form-control,
    &>.input-group-prepend>.input-group-text,
    &>.input-group-append>.input-group-text,
    &>.input-group-prepend>.btn,
    &>.input-group-append>.btn {
      padding: 0 1rem;
      border-radius: 0;
    }
  }

  .form-check {
    padding-left: 0;
    margin-top: 0.5rem;

    [type='checkbox'],
    [type='radio'] {
      position: absolute;
      left: -9999px;

      +label {
        position: relative;
        padding-left: 36px;
        cursor: pointer;
        display: inline-block;
        height: 32px;
        line-height: 32px;
        font-size: 1rem;
        user-select: none;
      }

      //focus
      &:focus+label {
        @extend :focus;
      }

      &:focus.focus--mouse+label {
        @extend .focus--mouse;
      }
    }

    [type='checkbox'] {

      +label::after,
      +label::before {
        content: '';
        left: 0;
        position: absolute;
        transition: 0.2s ease-out;
        z-index: 1;
        border-style: solid;
        border-width: 2px;
      }

      +label::before {
        top: 0;
        width: 17px;
        height: 17px;
        border: 1px solid #e6e9f2;
        border-radius: 1px;
        margin: 2px 5px;
        transform: rotateZ(37deg);
        transform-origin: 100% 100%;
      }

      +label::after {
        border-radius: 4px;
        height: 20px;
        width: 20px;
        margin: 6px 5px;
        top: 0;
      }

      &:checked+label::before {
        top: 6px;
        left: 1px;
        width: 8px;
        height: 13px;
        border-style: solid;
        border-width: 2px;
        border-color: transparent #fff #fff transparent;
        transform: rotate(40deg);
        backface-visibility: hidden;
        transform-origin: 100% 100%;
        opacity: 0.8;
      }

      &:checked+label::after {
        border-color: $primary;
        background-color: $primary;
        z-index: 0;
      }

      &:not(:checked)+label::after {
        background-color: transparent;
        border-color: #5c6f82;
        z-index: 0;
      }

      &:not(:checked)+label::before {
        width: 0;
        height: 0;
        border-color: transparent;
        left: 6px;
        top: 10px;
      }

      &:disabled+label {
        cursor: not-allowed;
      }

      &:disabled:not(:checked)+label::after {
        border-color: #e6e9f2;
        background-color: #fff;
      }

      &:disabled:checked+label::after {
        background-color: #e6e9f2;
        border-color: #e6e9f2;
      }
    }

    [type='radio'] {
      +label {
        transition: 0.2s ease-out;

        &::after,
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          margin: 5px;
          width: 22px;
          height: 22px;
          z-index: 0;
          border-radius: 50%;
          border-style: solid;
          border-width: 2px;
          transition: 0.2s ease-out;
        }
      }

      &:not(:checked)+label {

        &::after,
        &::before {
          border-color: #5c6f82;
        }

        &:after {
          z-index: -1;
          transform: scale(0);
        }
      }

      &:checked+label {
        &::after {
          border-color: $primary;
          background-color: $primary;
          z-index: 0;
          transform: scale(0.64);
        }

        &::before {
          border-color: $primary;
        }
      }

      &:disabled {
        &+label {
          cursor: not-allowed;
        }

        &:not(:checked)+label {

          &::after,
          &::before {
            border-color: #e6e9f2;
          }
        }

        &:checked+label {
          &::after {
            border-color: #e6e9f2;
            background-color: #e6e9f2;
          }

          &::before {
            border-color: #e6e9f2;
          }
        }
      }
    }

    // gruppi
    &.form-check-group {
      padding: 0 0 8px 0;
      margin-bottom: 16px;
      box-shadow: inset 0 -1px 0 0 rgba(1, 1, 1, 0.1);

      [type='checkbox']+label,
      [type='radio']+label {
        position: static;
        padding-left: 8px;
        padding-right: 52px;

        &::after,
        &::before {
          right: 15px;
          left: auto;
        }
      }

      [type='checkbox']:checked+label::before {
        right: 26px;
      }

      [type='radio']:checked+label::before {
        right: 15px;
      }

      .form-text {
        opacity: 0.6;
        margin: 0;
        padding-left: 8px;
        padding-right: 52px;
      }
    }
  }
}

.filters {
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.10);
  display: flex;
  padding: 2.5em 1.2em 0;
  width: 100%;
  margin: 2em auto;
  background-color: #f6f4f4;
  justify-content: space-between;

  .bootstrap-select-wrapper {
    button .filter-option .filter-option-inner {
      line-height: 1.5;
    }
  }
  .alphabet {
    a {
      padding: 0 3px;
      font-weight: 400;
      &.active {
        font-weight: 600;
        color: $italia;
      }
    }
  }
}