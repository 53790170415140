.researcher-container {
    position: relative;
    background-color: white;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        background-size: 19%;
        background-position: center right;
        background-repeat: no-repeat;
        opacity: 0.5;
    }

    &.biology {
        background-color: $biology;
        &:before {
            background-image: url("/assets/img/icons/cell-biology-w.svg");
            
        }
    }

    &.genomic {
        background-color: $genomic;
        &::before {
            background-image: url("/assets/img/icons/genomic-medicine-w.svg");
        }
    }

    &.molecular {
        background-color: $molecular;
        &:before {
            background-image: url("/assets/img/icons/molecular-therapy-w.svg");
        }
    }

    .researcher-img {
        max-width: 350px;
    }

    .text {
        color: white;
        font-size: 1.3rem;
        font-weight: 500;
    
        a {
            color: white;
        }
    }
}

.tabs {
    max-width: 100%;
    flex: auto;
}

@media (max-width: #{map-get($grid-breakpoints, lg)}) {
    .researcher-container {
        &::before {
            top: 90%;
            right: 0;
            background-size: 40%;
        }

        .researcher-img{
            max-width: 100%;
        }

        .text {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
}