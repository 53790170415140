::selection {
  background: rgba(0, 115, 230, 0.28);
}

::-moz-selection {
  background: rgba(0, 115, 230, 0.28);
}

.owl-carousel {
  z-index: 0;
}

body {
  margin: 0;
  font-family: $lato;
  font-weight: 300;
  color: #323254;
  text-align: left;
  background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-sans-serif;
  position: relative;
  a {
    color: #323254;
    text-decoration: none;
  }
}

h6 {
  font-size: 0.85rem
}

h3 {
  margin-top: 3em;

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    background-color: $secondary-color;
    height: 10px;
    width: 78px;
    border-radius: 4px;
  }
  
  &::after {
    content: "";
    position: absolute;
    top: -20px;
    left: 90px;
    background-color: $italia;
    height: 10px;
    width: 20px;
    border-radius: 4px;
  }
  
}


section {
  margin: 0;
  &-title {
    color: $primary;
  }
}


.grey-bg {
  background-color: $light-grey;
  margin: 0;
}

.img-container {
  overflow: hidden;
}

.cover-img {
  object-fit: contain;
  // height: auto;
  // width: 100%;
}

.center-height {
  display: flex;
  align-items: center;
}

.center-text {
  text-align: center;
}

.callout {
  border: none;
  margin: .5rem 0;
}

p.callout {
  background: #eee;
  padding: .5rem;
  border-left: 1em solid #e0e0e0;
  clear: both;
}

.white {
  color: $white;
}

a.white {
  color: $white;
  text-decoration: none;
  .icon {
    fill: $white;
  }
  &:hover {
    color: $white;
    text-decoration: underline;
    cursor: pointer;
  }
}


.green {
  color: $green;
  .icon {
    fill: $green;
  }
  &:hover {
    color: $white;
    text-decoration: underline;
  }
}

.section-with-bg {
  background-color: $light-grey;
  display: block;
  position: relative;
  height: auto;
  padding: 2em 0 3em;
}

.link-list {
  margin: 3em auto 1em;
  div {
    a {
      text-decoration: none;
      font-weight: 600;
      font-size: 1.1em;
    }
  }
}

.banner-intro {
  position: relative;
  //padding: 96px 0 0;
  margin: 0;
}

.bg-section {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: absolute;
  top: 0;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.bg-page {
  background-color: $light-grey;
  padding-top: 3em;
  padding-bottom: 3em;
}

.first {
  padding-bottom: 5em;
}

.page {
  box-shadow: 4px 4px 8px 0 rgba(90,103,114,0.1);
  background-color: $white;
  border-radius: 4px;
  position: relative;
  padding: 1em 3em 3em;
  min-height: 0;


  &-title {
    color: $primary;
    font-size: 2.222em;
    margin: 0;
    letter-spacing: 0;
  }
  &-description {
    padding-bottom: 2em;
    color: $black;
  }
}

.link-with-icon {
  margin: 3%;
  align-items: center;
  a {
    font-weight: 600;
    &:hover {
      color: $green;
      i {
        color: $green;
      }
    }
  }
}

.responsive {
  width: 100%;
  height: auto;
}

.mosaic-tile .icon-md,
.icon-md {
  max-width: 60px;
  height: auto;
  padding: 10px;
}

.mosaic-tile .icon-large,
.icon-large {
  max-width: 200px;
  height: auto;
  padding: 10px;
}

.internal-link {
  color: $secondary-color;
}

a.read-more {
  display: flex;
  align-items: center;
  color: $secondary-color;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: $card-category-l-spacing;
  font-size: $card-category-size;
  .icon {
    margin-left: $v-gap;
    fill: $secondary-color;
    width: $card-link-icon-size;
    height: $card-link-icon-size;
    flex-shrink: 0;
  }
}

.it-header-wrapper {
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
}

#main_container {
  margin-top: 150px;
  display: block;
}

footer section {
  margin: 0;
}

.form-group {
  width: 100%;
}

.margin-bottom {
  margin-bottom: 1.5em;
}

.margin-top {
  margin-top: 1.5em;
}

.bg-second-color {
  background-color: #f3f3f3;
  padding: 24px 0;
}

.spalla-destra {
  padding: 1em;
  display: flex;
  flex-direction: column;
  h3 {
    color: #661224;
    font-size: 1.3em;
    margin: 0 0 1em;
  }
}

.flex-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.last-update {
  font-size: 13px;
  color: $black;
  margin-top: 0.5em;
  padding: 10px;
  font-weight: bold;
}

.container {
  padding-left: 24px;
  padding-right: 24px;
}

.collaborators {
  margin-bottom: 80px;

  .row {
    .col-12.col-lg-6 {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      max-width: 80%;
    }
  }
}

.hr {
  border-top: 4px solid $italia;
}

.row-equal-height> [class*='col-'] {
  display: flex;
  flex-direction: column;
}


// Plone fix
.plone-toolbar-left #edit-zone {
  z-index: 11;
}

.newsImageContainer {
  float: right;
  padding-left: 1em;
}

.documentFirstHeading {
  font-size: 2.222rem;
 line-height: 1.02;
 letter-spacing: 0;
 color: $italia;
}

.documentDescription {
 padding-bottom: 2em;
 color: #000;
 font-weight: 400;
 font-size: 1.333rem;
}

.width-full + .grey-bg {
  padding-top: 7em;
}

.section-news .grey-bg {
  background-color: #fff;
}

.section-news .page {
  box-shadow: none;
}

body.template-default_error_message #content-core p {
  white-space: normal;
}

.social-buttons .glyphicon.link-external,
.social-buttons .glyphicon.link-https {
  display: none;
}

.portaltype-museum #content ul,
ul.searchmuseumlist {
  list-style: none; padding-left: 0;
}

.portaltype-museum #content table.vertical.listing {
  width: 100;

}
.portaltype-museum #content table.vertical.listing tr {
  vertical-align: top;
}
.portaltype-museum #content table.vertical.listing td {
  padding-left: 1em;
}

.template-search_gare #content select {
  width: 100%;
}

.mode_selector {
  display: none;
}

.portletHeader.hiddenStructure {
  display: none;
}

.nomargin {
  margin: 0;
}

.hiddenStructure {
  display: none;
}

form.horizontal {
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 2px 5px #ccc;
  margin-bottom: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  #field-text,
  .formControls {
    width: 100%;
    margin: 1em auto;

    input {
      display: block;
      text-align: center;
      border: none;
      background-color: #00b212;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 3px;
      color: #fff;
      width: 100%;
      margin: 1em 0;
    }
  }
}

.box-contatti {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f3f3f3;
  padding: 1em;
  -webkit-box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 1em 0 1.5em;
  img {
      width: 45%;
      height: auto;
  }
}

.listing dl {
  border-bottom: 1px solid #ccc;
}

.image-inline {
    float: none;
    padding: 1em;
}

.link-external,
#content a {
    font-weight: 700;
}

.list-group-item {
  border-bottom: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 1px solid #f3f3f3;
}

// Navtree

.navTree li.list-group-item {
  a.state-published {
      line-height: 1em;
      padding-top: 0;
      padding-bottom: 0;
      font-size: .9em;
      color: #000;
      font-weight: 600;
  }
  a.navTreeCurrentItem {
      font-weight: 700;
  }
}

.navTree li.navTreeCurrentNode {
  border-left: 2px solid #00b213;
}

nav.portletContent {
  padding-right: 1em;
}

.list-group-flush .list-group-item {
  line-height: 1.2;
}

.list-group-item.navTreeItem {
  padding: .5rem 1.25rem .5rem .8rem;
  img {
      display:none;
  }
}

.navTreeCurrentItem.navTreeCurrentNode {
  font-weight: 700;
}

.navTree.navTreeLevel1,
.navTree.navTreeLevel2,
.navTree.navTreeLevel3 {
    padding: 0.8em 0 0 0;
    font-size: .95em;
}

.navTree.navTreeLevel4 {
    padding-left: .3rem;
}

.portlet-collection-progetti,
.portlet-collection-argomenti-in-evidenza,
.portlet-collection-progetti-in-corso,
.portlet-collection-topics-in-evidence  {
   margin-bottom: 0;
}

.portlet-collection-progetti-in-corso  {
   padding-bottom: 2em;
}

i.glyphicon {
  display: none;
}

article.tileItem {
  font-size: 0.95em;
   margin: 1em 0;
   border-bottom: 1px solid #ccc;
   padding: 0.5em 0;
   .tileHeadline {
       font-size: 1.85em;
       letter-spacing: 0;
    }
}

#events {
  margin: 0;
}

// Tables

table.plain tr td,
table.Elenco tr td,
table tr td {
    border: 1px solid #d6d5d5;
    padding: 5px;
}

.portletCollection {
  .portletHeader {
    font-weight: 700;
    font-size: 1.5em;
  }
  ul.portletContent {
    list-style-type: none;
    padding-left: 0;
    li.portletItem {
      margin: .5em 0;
      padding-bottom: .6em;
      border-bottom: 1px solid #f3f3f3;
      a {
        font-weight: 600;
      }
    }
  }
  .portletFooter a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #00b212;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .9px;
    font-size: .77778rem;
  }
}


.event .join-button a {
  padding: .3em 0;
  margin-top: 1em;
}

.newsImage {
  min-width: 230px;
  width: 100%;
  height: 100%;
}

input.standalone.easynewsletter_button:hover,
.event .join-button a:hover {
  color: #fff;
  background-color: #008c0e;
  border-color: #007f0d;
}

.newsletterIssues li {
 padding: 0.5em 0;
 border-bottom: 1px solid #ccc;
 width: 93%;
 max-width: 93%;
}

.plone-modal-wrapper.image-modal img {
  width: 100%;
  height: auto;
}

.portlet-collection-in-primo-piano .card-title  {
  padding-top: 1em;
}

.plone-modal-close {
  position: relative;
  z-index: 2;
}

#portletPageColumns {
  width: 100%;
  display: flex;
  overflow: hidden;
  margin: 0;
  align-content: flex-start;
  padding: 0;
  align-items: flex-start;
  flex: 1;
  flex-wrap: wrap;
}

#portletPageTopRow {
  width: 100%;
}

#portletPageFirstColumn,
#portletPageSecondColumn {
  float: none;
}

#portletPageColumns .portletHeader {
  font-weight: 700;
  font-size: 1.5em;
}

section.portletContent,
section.portlet {
  margin: 0;
}

.full-image {
  padding:0;
  width: 100%;
  height: auto;
}

#categories-filed-under {
  font-size: .85em;
  font-style: italic;
}

ul[aria-labelledby="categories-filed-under list-inline d-inline"]{
  padding-left: 0;
  margin-top: .5em;
}

#it-access-top-wrapper {
  display: none !important;
}



//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {
  .flex-row {
    flex-wrap: nowrap;
  }
  .container.page  {
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 0;
  }
  .section-title {
    text-align: left;
    margin-bottom: 40px;
  }
}


//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {

  #main_container {
    margin-top: 155px;
  }
  .flex-row {
    flex-wrap: nowrap;
  }
}

// Animations
*[data-animated="true"] {
  & > * {
    opacity: 0;
    transform: translateY(25px);
  }
}

.animate {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.card.card-img .img-responsive-wrapper .img-responsive .img-wrapper {
  display: flex;
  align-items: center;
}


.img-responsive-wrapper .img-responsive .img-wrapper img.logos {
  max-width: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.description {
  margin-bottom: 1.5em;
}




//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {
  
}
