$bg-footer: $italia;

.footer-logos {
  background-color: #fff;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  li {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    flex-shrink: 1;
    max-width: 110px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.section-loghi {
  background-color: $white;
}

#footer {
  background-color: $italia;
  color: $white;
  padding-top: 22px;
  a {
    color: #fff;
    &:hover {
      color: #00b213;
    }
  }
  section {
    margin-bottom: 28px;
    .intestazione {
      margin-bottom: 28px;
    }
  }
 .postFooter {
    font-size: 0.8889em;
    padding: 3px 0;
    color: #dedede;
    background-color: $dark-blue;
    margin-bottom: 0 !important;
  }
  .logoimg {
    display: inline-block;
  }
  .logotxt {
    vertical-align: middle;
    height: 48px;
    text-align: left;
    margin-left: 15px;
    display: inline-block;
    h3 {
      display: table-cell;
      vertical-align: bottom;
      margin: 0;
      font-weight: 700;
      color: #fff;
      font-size: 1.778em;
      height: 48px;
      a {
        color: #fff;
        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
  .logoimg img {
    height: 48px;
    max-height: 48px;
  }
  h4 {
    color: #fff;
    font-size: 0.778em;
    font-weight: 700;
    margin: 0;
    padding: 0 0 16px 0;
    line-height: 24px;
    text-transform: uppercase;
    vertical-align: middle;
    a {
      vertical-align: middle;
    }
    svg.icon {
      width: 22px;
      height: 22px;
      opacity: 0.5;
      margin-right: 16px;
      fill: #fff;
    }
  }
  p {
    margin: 16px 0;
    color: #fff;
    font-size: 0.889em;
    a {
      color: #fff;
      &:hover {
        color: $green;
      }
    }
  }
  .footer-list {
    list-style-type: none;
    margin: 15px 0;
    padding: 0;
    font-size: 0.889em;
    li {
      margin: 0;
      a {
        padding: 0;
        display: block;
        color: #fff;
        text-decoration: none;
        &:hover {
          color: $green;
        }
      }
    }
  }
  .lista-linkutili .footer-list {
    font-size: 0.889em;
  }
}


//mobile
.it-footer-main {
  background-color: $bg-footer;
  color: $white;
  font-size: 16px;

  h4 a {
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
  }

  .link-list-wrapper ul li {
    a {
      padding: 0;
      font-size: 1em;
      line-height: 2em;
      color: $white;
    }
  }

  .it-brand-wrapper {
    padding: $v-gap * 4 0;

    .it-brand-text {
      padding-right: $v-gap * 3;
    }

    a {
      display: flex;
      color: $header-center-text-color;
      align-items: center;

      h2 {
        font-size: $header-center-h2-size-mob;
        margin-bottom: 0;
        font-weight: $header-center-h2-weight;
        letter-spacing: unset;
        line-height: 1.1;
      }

      h3 {
        font-size: $header-center-h3-size;
        font-weight: normal;
        margin-bottom: 0;
      }

      &:hover {
        text-decoration: none;
      }

      .icon {
        fill: $header-center-text-color;
        width: $header-center-icon-size-mob;
        height: $header-center-icon-size-mob;
        margin-right: $header-center-icon-margin-mob;
        flex-shrink: 0;
      }
    }
  }

  section {
    padding: 0 $v-gap * 2;
  }
}

.it-footer-small-prints {
  background-color: darken($bg-footer, 10%);

  ul.it-footer-small-prints-list {
    padding: 1.5rem 1rem;

    li {
      padding: 0.5rem 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  a,
  a:hover:not(.disabled) {
    color: $white;
    font-size: 15px;
  }
}

.logo-small {
  max-width: 100px;
}

.logo-md {
  max-width: 150px;
}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {
}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {
  .it-footer-small-prints {
    ul.it-footer-small-prints-list {
      li {
        padding: 0 1rem;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
}
