#events {
  background-color: $light-grey;
  padding: 7vh 0 1vh;
}

.calendar {
  padding: 0 0 56px 0;
  font-family: $font-family-sans-serif;
  &-filter {
    display: flex;
    justify-content: flex-end;
  }
  .btn {
    margin: 0 0.5em;
  }
  h4 {
    color: #fff;
    font-size: 1.111em;
    text-align: center;
    border-radius: 4px 4px 0 0;
    background-color: $btn-primary-bg;
    margin: 32px 0 0 0;
    padding: 10px 0;
  }
  .owl-stage-outer {
    background-color: $white;
    border-radius: 0 0 4px 4px;
    box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.1);
    padding-top: 0;
  }
  .calendar-filter {
    flex-direction: column;
    .btn {
      margin-top: 15px;
    }
  }
  .calendar-card {
    box-shadow: 8px 0 32px -5px #b1b1b1;
    min-height: 380px;
    padding: 0;
    background-color: $white;
    .progress-spinner {
      margin: 0 auto;
      &.progress-spinner-active:not(.progress-spinner-double) {
        opacity: .5;
        border-color: #7f9bc0 #7f9bc0 #e5ebf2;
      }
    }
    &-data {
      color: $green;
      font-weight: 300;
      font-size: .889em;
      padding: 12px 22px;
      text-transform: capitalize;
      line-height: 1;

      strong {
        font-size: 2.000em;
        display: block;
        font-weight: 600;
      }
    }
    &-list {
      margin: 0;
      padding: 0 16px;
      color: $btn-primary-bg;
      list-style-type: none;
      li {
        border-top: 1px solid #d8d8d8;
        font-size: .889em;
        padding: 12px 0;
        margin: 0;
        &:first-child {
          border: 0;
        }
        a {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: #222;
          font-weight: 600;
          font-family: $font-family-sans-serif;
        }
        .hour {
          font-size: 12px;
          font-weight: 600;
          color: #777;
          display: block;
          font-family: $font-family-sans-serif;
        }
        .place {
          font-size: 14px;
          font-weight: 600;
          padding: 0;
          line-height: 1.5;
          color: #777;
          display: block;
          font-family: $font-family-sans-serif;
        }
        .event-img {
          max-width: 100px;
          margin-right: 1em;
        }
      }
    }
  }
}


// Events

.event {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &.summary.details {
      order: 2;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 0 1em 1em;
      margin: 1em 1em 2.5em 1em;
  }
  .event-date,
  .event-location,
  .event-calendar,
  .event-registration  {
    list-style-type: none;
    background-color: #f3f3f3;
    padding: 12px;
    border-radius: 3px;
    width: 100%;
    margin-bottom: .5em;
    strong {
      color: #00b212;
      display: block;
      text-transform: uppercase;
      font-size: .9em;
    }
  }
  .event-registration  {
    text-align: center;
    background-color: #efe3e3;
 }
}

#content .event .event-registration .edit-event a.btn {
  margin: .5em 0;
  padding: 10px 5px;
  font-weight: 600;
  line-height: 1.2;
  display: block;
  width: 100%;
}


//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {

  .calendar {
    .calendar-card {
      &-list {
        li {
          a {
            flex-direction: row;
          }
        }
      }
    }
  }

}



//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .calendar {
    .calendar-filter {
      flex-direction: row;
      .btn {
        margin-top: 0;
      }
    }
  }
}
