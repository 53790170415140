.it-carousel-wrapper {
  .owl-stage-outer {
    border: none !important;
  }

  .owl-dots {
    margin-top: 1em;
    text-align: center;
    .owl-dot {
      width: 13px;
      height: 13px;
      &.active {
        width: 16px;
        height: 16px;
      }
    }
  }
}


#gallery {
  margin: 0;
  padding: 0 30px 0 0;
  position: relative;

  .owl-dots {
    margin-top: 0;
  }

}


.it-single-slide-wrapper .img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 400px;
  }
}


.carousel-publications {
  margin-top: 2em;
  .card {
    box-shadow: none;
    -moz-box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;

    .card-body {
      .card-signature {
        margin-bottom: 5px;
        font-weight: 400;
      }

      .card-text {
        line-height: 1.4;
      }
    }
  }
}


.it-carousel-wrapper.it-carousel-landscape-abstract .owl-dots {
  left: 0;
  text-align: center;
  bottom: 0;
  padding-left: 0;
  margin-left: 0;
  position: relative;
}


.avatar {
  margin-left: 10px;
  margin-top: 10px;
}

.category {
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 1px;
  font-weight: 500;
}

.four-columns-owl {
  .card-title {
    text-align: center;
  }
}

// Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {

  .owl-item {
    .researcher {
      min-height: 475px;
    }
  }


  .it-carousel-wrapper.it-carousel-landscape-abstract .owl-stage .owl-item .it-single-slide-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .two-columns-carousel {
    .it-single-slide-wrapper {
      max-width: 360px;
    }
  }

}


