.section-blu {
    background-color: $primary;
    p {
        color: white;
    }
    a {
        font-weight: 600;
        color: white;
    }
}

.section-red {
    background-color: $secondary-color;
    p {
        color: white;
    }
    a {
        font-weight: 600;
        color: white;
    }
    .it-carousel-wrapper .owl-dots .owl-dot {
        background-color: transparent;
        border: 1px solid $white;
        opacity: 1;
        &.active {       
            opacity: 1;
            background-color: $white;
        }
    }
}

.section-with-opacity {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .8;
        background-color: $secondary-color;
    }
}