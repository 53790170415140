.nav-link {
  font-family: $font-family-sans-serif;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.blocked-icon {
  text-align: center;
  fill: $italia;
}


.nav-link:hover {
  .blocked-icon {
    fill: $secondary-color;
  }
}

.navbar .dropdown-menu .link-list-wrapper, 
.navbar .submenu .link-list-wrapper {
  margin-top: -50px;
}

.navbar .dropdown-menu .link-list-wrapper ul li .divide {
  width: 81%;
  background: #e6ecf2;
  margin-left: 15px;
}

.link-list li {
  clear: both;
  width: 100%;
}



//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {
  .blocked-icon {
    width: 100%;
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .navbar {
    background: $navigation-bg-color-desktop;
    padding: 0;
    .navbar-collapsable {
      .navbar-nav {
        position: relative;
        li {
          position: relative;
          justify-content: center;

          &:hover {
            .submenu, 
            .subemnu:hover {
              // display: block;
              visibility: visible;
              opacity: 1;
              display: block;
            }
          }

          .submenu {
            top: calc(100% - 8px);
            box-shadow: 0 5px 10px $text-color-grey;
            background-color: #fff;
            border-radius: 4px;
            color: #19191a;
            font-size: 1rem;
            text-align: left;
            visibility: hidden;
            padding: .5rem 0 0;
            opacity: 0;
            min-width: 10rem;
            position: absolute;
            transition: all 0.5s ease;
            margin: .125rem auto 0;
            display: none;
            z-index: 5;

              &::before {
                display: block;
                content: '';
                position: absolute;
                top: -6px;
                left: 30%;
                width: 18px;
                height: 18px;
                border-radius: 4px;
                background-color: #fff;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                z-index: 2;
              }
          }

          &.active {
              &::after {
                content: '';
                height: 9px;
                width: 80%;
                min-width: 50px;
                display: block;
                position: absolute;
                background-color: $secondary-color;
                border-radius: 3px;
                bottom: 0px;
                z-index: 0;
                left: 10%;
              }
          }
          a.nav-link {
            font-weight: 600;
            color: $italia;
            border-bottom: none;
            width: max-content;
            font-size: 20px;
            position: relative;
            display: inline-block;

            span + span {
              font-size: 22px;
            }

            &.dropdown-toggle {
              svg {
                fill: $italia;
              }
            }

            &.active {
              border-left: 0;
              background: transparent;
              color: $secondary-color;

              .blocked-icon {
                fill: $secondary-color;
              }
            }

            &:focus {
              outline: none;
            }

            &:hover,
            &:hover:not(.active) {
              text-decoration: none;
              color: $secondary-color;
              &::after {
                content: '';
                height: 9px;
                width: 80%;
                min-width: 50px;
                display: block;
                position: absolute;
                background-color: $secondary-color;
                border-radius: 3px;
                bottom: 0px;
                left: 10%;

                &.dropdown-toggle {
                  svg {
                    fill: $secondary-color;
                  }
                }

                .blocked-icon {
                  fill: $secondary-color;
                }
              }
              
            }


            &.disabled {
              opacity: $navigation-disabled-item-opacity;
              cursor: not-allowed;
            }
          }

          .dropdown-menu {
            box-shadow: 0 5px 10px $text-color-grey;
          }
        }
      }
    }
  }
}

@media only screen and (min-width:990px) and (max-width:1293px) {
  .navbar .navbar-collapsable .navbar-nav li a.nav-link {
    font-size: 1.7vw;
    padding: 10px;

    span+span {
      font-size: 1.6vw;
    }
  }
}
  