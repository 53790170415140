// mosaic override
.mosaic-grid-row {
    margin-left: 0;
    margin-right: 0;
    iframe { border: none; width: 100%; }
  &.row-equal-height {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -6px;
    margin-left: -6px;
    > .mosaic-grid-cell {
      display: flex;
      flex-direction: column;
      .mosaic-tile,
      .mosaic-tile-content {
        height: 100%;
      }
      .card-wrapper {
        height: 100%;
        .card { height: 100%; }
      }
    }
  }
}

.mosaic-width-full {
    padding-left: 0;
    padding-right: 0;
}

.fade.in {
  opacity: 1 !important;
}

// image background images

.research-image {
  background-image: url('../assets/img/slider.jpg');
}

.researcher-image-index {
  background-image: url('../assets/img/research-index.png');
}

.generic-slider {
  background-image: url('../assets/img/slider.jpg');
}

.phd-programs {
  background-image: url('../assets/img/telethon-bg.png');
}

.discover-image {
  background-image: url('../assets/img/banner-vision.jpg');
}

.research-faculty-image {
  background-image: url('../assets/img/slider.jpg');
}

.research-faculty {
  background-image: url('../assets/img/about.jpg');
}

.research-policy {
  background-image: url('../assets/img/texas-children-hospital.jpg');
}

.after-phd {
  background-image: url('../assets/img/after.png');
}

.dissemination {
  background-image: url('../assets/img/meet.png');
}

.dissemination-fundrising {
  background-image: url('../assets/img/dissemination.jpg');
}

.walk-of-life {
  background-image: url('../assets/img/walk.png');
}

.focus {
  background-image: url('../assets/img/focus.jpg');
}

.children {
  background-image: url('../assets/img/children.png');
}

