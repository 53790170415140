.star-list {
    list-style-type: none;
    padding-left: 0;
    margin: 2em auto;

    li {
      margin: 1em auto;
      display: flex;
      align-items: center;

      img {
        max-width: 20px;
        margin-right: 1em;
      }
    }
  }

  .welcome {
    background-color: $white;
    z-index: 1;
    margin: 0 auto;
    position: relative;

    &-container {
      align-items: center;
    }

    &-text {
        background: #fff;
        padding: 0;
        border-radius: 4px;
        @media (min-width: #{map-get($grid-breakpoints, lg)}) {
          padding-right: 7em;
        }
    }

    &-img {
        position: relative;
        margin-top: 3em;
    }
  }


  .mission-box {
    background-color: $secondary-color;
    color: $white;
    padding: 2em 1em;
    text-align: center;
    position: relative;
    width: 70%;
    height: max-content;
    border-radius: $border-radius;
    margin-bottom: 2em;
    margin-top: -10em;
    left: 28vw;
    transition: .3s ease-in-out;
    &:hover {
      -webkit-box-shadow: 0 10px 50px -5px rgba(51,71,91,.12);
      box-shadow: 0 10px 50px -5px rgba(51,71,91,.12);
      -webkit-transform: scale(1.08);
      transform: scale(1.08);
    }

    h3 {
      margin-top: 0.5em;
      &::after, 
      &::before {
        display: none;
      }
    }
  }


  @media (min-width: #{map-get($grid-breakpoints, lg)}) {

    .mission-box {
      width: 60%;
      left: -50px;
      top: 15vw;
      position: absolute;
    }

    .welcome {
      margin: 5em auto 6em;

      &-container {
        padding-top: 2em;
      }

      &-img {
        margin-top: 0;
        max-height: 100%;
      }
    }
  }

  