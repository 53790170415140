.it-timeline-wrapper {
    margin-top: 36px;
    
    .timeline-element {
        .it-pin-wrapper {
            .pin-text {
                background-color: $italia;

                span {
                    color: $white;

                    &::after {
                        background-color: $italia;   
                    }
                }
            }

            .pin-icon {
                background: $secondary-color;
            }
        }
        
        .card {
            display: -webkit-box;
            -webkit-box-orient: horizontal;

            .card-body {
                text-align: left;
            }
        }
    }
}