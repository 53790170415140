.card-wrapper {
  padding-bottom: $v-gap * 3;
  display: flex;
  flex: 1
}

.card {
  padding: 0;
  position: relative;
  width: 100%;
  background: $white;
  box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.1);
  border: 1px solid $light-grey;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 0.5em;

  &.card-hero .card-body p,
  &.card-hero .card-body a {
    color: $white;
  }

  &::after {
    display: none;
  }

  &.colored-card {
    .icon {
      fill: $secondary-color;
    }
  }

  .card-body {
    padding: $card-padding;
    border-radius: 4px;
    overflow: hidden;
    font-family: $font-family-sans-serif;
    &.card-text {
      font-family: $lato;
      font-size: 1rem;
    }
    h5 {
      &.card-title {
        transition: all 0.3s;
        a {
          font-size: 1.2rem;
          line-height: 1.3;
          font-weight: 500;
          margin: 0 0 8px 0;
        }
      }
    }
    h6.card-subtitle {
      font-weight: normal;
      margin-bottom: $v-gap * 2;
      margin-top: -$v-gap * 2;
    }
    a {
      h5.card-title {
        color: $secondary-color;
      }
    }
    p {
      font-family: $font-family-sans-serif;
      font-size: 0.95em;
      line-height: 1.5;
      color: $card-p-color;
    }
    .data {
      font-weight: 600;
      font-size: 0.8rem;
      line-height: 24px;
      margin-bottom: 1.5em;
      display: block;
    }
    .card-signature {
      font-style: italic;
      font-family: $font-family-sans-serif;
      color: $card-p-color;
      font-weight: 700;
      font-size: $card-signature-size;
      margin-bottom: $v-gap * 3;
      display: block;
    }
    .card-text {
      font-family: $lato;
    }
    .category-top {
      font-size: $card-category-size;
      text-transform: uppercase;
      color: $card-p-color;
      margin: 0;

      a.category {
        color: $primary;
        letter-spacing: 1px;
        font-size: 0.95em;
        text-transform: uppercase;
        font-weight: 600;
        padding: 16px 24px 0 0;
        margin-bottom: 0.75em;
        display: inline-block;
        text-decoration: none;
        i {
          margin-right: 0.35em;
        }
      }
      .category {
        font-weight: bold;
        font-family: $font-family-sans-serif;
        letter-spacing: $card-category-l-spacing;
      }
    }
  }

  &:after {
    display: none;
  }
  a.read-more {
    position: relative;
    bottom: auto;
    padding: 12px 0 0;
    &.white-btn {
      color: $white;
      margin-top: 1em;
      font-size: 1.3em;
      .icon {
        fill: $white;
      }
    }
  }
  .categoryicon-top {
    margin-bottom: $card-cat-icon-block-margin;
    display: flex;
    align-items: center;
    .text {
      font-size: $card-category-size;
      text-transform: uppercase;
      letter-spacing: $card-category-l-spacing;
      color: $card-p-color;
      line-height: 1rem;
    }
    .icon {
      width: $card-cat-icon-size;
      height: $card-cat-icon-size;
      fill: $card-link-color;
      margin-right: $v-gap * 1;
      flex-shrink: 0;
    }
  }
  .simple-link {
    font-weight: 600;
    font-size: $card-signature-size;
    margin-top: $card-simple-link-margin;
    display: block;
  }
  
  &.card-bg,
  &.card-event {
    margin-left: $card-padding/3;
    margin-right: $card-padding/3;
    box-shadow: $card-shadow;
    background-color: $card-shadow-bg;
    border-right: none;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;

    &:after {
      background: transparent;
    }
  }

  &.card-bg {
    background-image: url('../assets/img/cards-icon.png') !important;
  }

  &.card-event {
    background-image: url('../assets/img/cards-bg.png') !important;
  }

  &.event-card {
    background-image: url('../assets/img/cards-bg.png') !important;
  }

  &.card-img {
    margin-left: $card-padding/3;
    margin-right: $card-padding/3;
    border: 1px solid $light-grey;
    h5.card-title {
      font-size: $card-img-heading-size;
      line-height: $card-img-heading-l-h;
    }
    .img-responsive-wrapper {
      .img-responsive {
        padding-bottom: 61.29%; /* image proportion 310 x 190 */
        .img-wrapper {
          border-radius: 3px 3px 0 0;
          overflow: hidden;
        }
      }
    }
  }
  &.no-after {
    &:after {
      display: none;
    }
    a.read-more {
      position: inherit;
    }
  }
  &.special-card {
    .img-responsive-wrapper {
      overflow: visible;
      position: relative;
      width: $special-card-img-width;
      margin-bottom: $card-padding;
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
      }
      &:before {
        background: $primary-a3;
        left: $card-padding;
        top: $card-padding;
        opacity: 0.3;
        transition: all 0.3s;
      }
      &:after {
        background: $primary-a3;
        left: $card-padding/2;
        top: $card-padding/2;
        transition: all 0.3s;
      }
      .img-responsive {
        padding-bottom: 122.98%; /* image proportion 214 x 174 */
        z-index: 1;
        .img-wrapper {
          border-radius: 3px 3px 0 0;
          overflow: hidden;
        }
      }
    }
    .head-tags {
      margin-bottom: $card-padding;
    }
    &:hover {
      text-decoration: none;
      h5 {
        color: $primary-a6;
      }
    }
  }
  &.card-big {
    .card-body {
      padding: $card-padding * 2;
      .top-icon {
        margin-bottom: $card-padding;
        .icon {
          width: 100px;
          height: 100px;
          fill: $primary-a6;
        }
      }

      h5.card-title {
        font-size: $card-big-h5-size;
        line-height: $card-big-h5-l-h;
      }
    }
    .flag-icon {
      margin-left: 58px;
    }
    .etichetta {
      position: absolute;
      top: $card-padding + $v-gap;
      justify-content: flex-end;
      display: flex;
      align-items: flex-start;
      max-width: 200px;
      right: 0;
      left: auto;
      padding-right: $card-padding;
      text-align: left;

      img.icon {
        display: inline;
        width: 50px;
        height: 50px;
      }
    }
  }
  &.border-bottom-card {
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 4px;
      bottom: 0;
      background: $primary-a6;
    }
  }
  .head-tags {
    justify-content: space-between;
    display: flex;
    margin-bottom: $card-padding * 2;
  }
  .card-tag {
    color: $primary-a6;
    font-size: $card-category-size;
    font-weight: bold;
    letter-spacing: $card-category-l-spacing;
    padding: 0 $v-gap * 4;
    border-radius: $tag-radius;
    border: 1px solid $primary-a6;
  }
  .it-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $card-padding * 2;
    .card-signature {
      font-style: italic;
      font-family: $font-family-sans-serif;
      color: $card-p-color;
      font-weight: 700;
      font-size: $card-signature-size;
      display: block;
      margin-bottom: 0;
    }
  }

  &.box {
    box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.2);
    margin: 0 0 1em;
    padding: $card-padding;
    border-radius: 4px;
    width: 95%;
    height: auto;
    &.snpa {
      align-content: center;
      text-align: center;
      padding: 0;
      img {
        max-width: 360px;
        height: auto;
        padding: 1.5em 1em;
      }
    }
  }
  &.seguici {
    font-size: 1em;
    font-weight: 600;
    i {
      margin-right: 0.3em;
    }
  }

  &.event-card {
    .card-body {
      padding-top: 0.5em;
    }
    .category-top {
      margin-top: 0;
      .data {
        &:before {
          display: none;
        }
        .day {
          color: $light-italia;
          font-size: 3.5em;
          margin-top: 7px;
        }
        .year {
          color: darken($light-italia, 20%);
          font-size: 1.1em;
        }
      }
    }
  }

  &.twitter-card {
    .icon {
      fill: $italia;
    }
    .card-body {
      .data {
        margin-bottom: 0;
      }
      .card-text {
        font-size: 0.9em;
        padding-top: 0;
      }
    }
  }

  &-hero {
    border: none;
    background-color: rgba(21, 95, 167, 1);
    color: $white;
    margin-top: -10vh;
    .card-body p.it-category {
      color: $white;
      text-transform: uppercase;
    }
    .it-btn-container {
      margin-top: 1em;
    }
  }

  &.standard,
  &.service,
  &.staff,
  &.biology,
  &.genomic,
  &.molecular {
    background-color: $white;
  }
  &.standard .etichetta::before,
  &.service .etichetta::before,
  &.staff .etichetta::before {
    background-image: none;
    display: inline-flex;
    content: '';
    width: 125px;
    height: 50px;
    background-size: 77%;
    background-repeat: no-repeat;
  }
  &.biology .etichetta::before {
    background-image: url('../assets/img/icons/cell-biology.svg');
    display: inline-flex;
    content: '';
    width: 125px;
    height: 50px;
    background-size: 77%;
    background-repeat: no-repeat;
  }
  &.molecular .etichetta::before  {
    background-image: url('../assets/img/icons/molecular-therapy-colored.svg');
    display: inline-flex;
    content: '';
    width: 50px;
    height: 50px;
    background-size: 76%;
    background-repeat: no-repeat;
  }
  &.genomic .etichetta::before {
    background-image: url('../assets/img/icons/genomic-medicine-colored.svg');
    display: inline-flex;
    content: '';
    width: 50px;
    height: 50px;
    background-size: 76%;
    background-repeat: no-repeat;
  }

  &.service {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background-color: #C2CED7;

    &:hover {
      -webkit-box-shadow: 0 10px 50px -5px rgba(51, 71, 91, .12) !important;
      box-shadow: 0 10px 50px -5px rgba(51, 71, 91, .12) !important;
      -webkit-transform: scale(1.08) !important;
      transform: scale(1.08) !important;
    }

    .category {
      color: #727272;

      .icon {
        fill: #727272;
      }
    }

    .card-title a {
      color: #323254;

      .icon {
        fill: #323254;
        ;
      }
    }

    .icon {
      fill: #323254;
    }

    .card-body a,
    .card-body a h5.card-title,
    .card-body .card-text {
      color: #323254;
      text-decoration: none;
      font-weight: 500;
      font-size: 0.9em;
      line-height: 1.4;
    }

    &::before {
      background: #323254;
    }
  }

  &.service {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background-color: rgba(104, 165, 203, 0.13);

    &:hover {
      -webkit-box-shadow: 0 10px 50px -5px rgba(51, 71, 91, .12) !important;
      box-shadow: 0 10px 50px -5px rgba(51, 71, 91, .12) !important;
      -webkit-transform: scale(1.08) !important;
      transform: scale(1.08) !important;
    }

    .card-body {
      width: 100%;
      height: 100%;
      font-size: 1.2em;
    }

    .category {
      color: #C2CED7;

      .icon {
        fill: #C2CED7;
      }
    }

    .card-title a {
      color: #727272;

      .icon {
        fill: #727272;
        ;
      }
    }

    .icon {
      fill: #727272;
    }

    .card-body a,
    .card-body a h5.card-title,
    .card-body .card-text {
      color: #727272;
      text-decoration: none;
      font-weight: 500;
      font-size: 0.9em;
      line-height: 1.4;
    }

    &::before {
      background: #C2CED7;
    }
  }

  &.standard {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background-color: rgba(104, 165, 203, 0.13);

    &:hover {
      -webkit-box-shadow: 0 10px 50px -5px rgba(51, 71, 91, .12) !important;
      box-shadow: 0 10px 50px -5px rgba(51, 71, 91, .12) !important;
      -webkit-transform: scale(1.08) !important;
      transform: scale(1.08) !important;
    }

    .card-body {
      width: 100%;
      height: 100%;
      font-size: 1.2em;
    }

    .card-body a,
    .card-body a h5.card-title,
    .card-body .card-text {
      text-decoration: none;
      font-weight: 500;
      font-size: 0.9em;
      line-height: 1.4;
    }
  }

  &.staff {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background-color: rgba(104, 165, 203, 0.13);
    
    &:hover {
      -webkit-box-shadow: 0 10px 50px -5px rgba(51,71,91,.12) !important;
      box-shadow: 0 10px 50px -5px rgba(51,71,91,.12) !important;
      -webkit-transform: scale(1.08) !important;
      transform: scale(1.08) !important;
    }
    .card-body {
      width: 100%;
      height: 100%;
      font-size: 1.2em;
    }
    .category {
      color: #F2BA19;
      .icon {
        fill: #F2BA19;
      }
    }
    .card-title a {
      color: #C39308;
      .icon {
        fill: #C39308;;
      }
    }
    .icon {
      fill: #C39308;
    }

    .card-body a,
    .card-body a h5.card-title, 
    .card-body .card-text {
      color: #727272;
      text-decoration: none;
      font-weight: 500;
      font-size: 0.9em;
      line-height: 1.4;
    }
    &::before {
      background: #F2BA19;
    }
  }

  &.biology {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background-color: rgba(104, 165, 203, 0.13);
    &:hover {
      -webkit-box-shadow: 0 10px 50px -5px rgba(51,71,91,.12) !important;
      box-shadow: 0 10px 50px -5px rgba(51,71,91,.12) !important;
      -webkit-transform: scale(1.08) !important;
      transform: scale(1.08) !important;
    }
    .card-body {
      width: 100%;
      height: 100%;
      font-size: 1.2em;
    }
    .category {
      color: #323254;
      .icon {
        fill: #323254;
      }
    }
    .card-title a {
      color: #323254;
      .icon {
        fill: #323254;
      }
    }
    .icon {
      fill: $white;
    }
    .card-body a,
    .card-body a h5.card-title, 
    .card-body .card-text {
      color: #323254;
      text-decoration: none;
      font-weight: 500;
      font-size: 0.9em;
      line-height: 1.4;
    }
    &::before {
      background: #323254;
    }
  }

  &.genomic {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background-color: rgba(244, 222, 232, 0.75);
    &:hover {
      -webkit-box-shadow: 0 10px 50px -5px rgba(51,71,91,.12) !important;
      box-shadow: 0 10px 50px -5px rgba(51,71,91,.12) !important;
      -webkit-transform: scale(1.08) !important;
      transform: scale(1.08) !important;
    }
    .card-body {
      width: 100%;
      height: 100%;
      font-size: 1.2em;
    }
    .category {
      color: #413239;
      .icon {
        fill: #413239;
      }
    }
    .card-title a {
      color: #413239;
      .icon {
        fill: #413239;
      }
    }
    .icon {
      fill: $white;
    }
    .card-body a,
    .card-body a h5.card-title, 
    .card-body .card-text {
      color: #413239;
      text-decoration: none;
      font-weight: 500;
      font-size: 0.9em;
      line-height: 1.4;
    }
    &::before {
      background: #413239;
    }
  }

  &.molecular {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background-color:rgba(153, 178, 136,0.32);
    &:hover {
      -webkit-box-shadow: 0 10px 50px -5px rgba(51,71,91,.12) !important;
      box-shadow: 0 10px 50px -5px rgba(51,71,91,.12) !important;
      -webkit-transform: scale(1.08) !important;
      transform: scale(1.08) !important;
    }
    .card-body {
      width: 100%;
      height: 100%;
      font-size: 1.2em;
    }
    .category {
      color: #3a4733;
      .icon {
        fill: #3a4733;
      }
    }
    .card-title a {
      color: #3a4733;
      .icon {
        fill: #3a4733;
      }
    }
    .icon {
      fill: $white;
    }
    .card-body a,
    .card-body a h5.card-title, 
    .card-body .card-text {
      color: #3a4733;
      text-decoration: none;
      font-weight: 500;
      font-size: 0.9em;
      line-height: 1.4;
    }
    &::before {
      background: #3a4733;
    }
  }


  &.researcher {
    border: none;
    background-color: $white;
    background-image: none;
  
    .card-body {
      background-color: transparent;
    }
    .card-body a,
    .card-body a h5.card-title,
    .card-body .card-text {
      text-decoration: none;
      font-weight: 500;
      font-size: 0.9em;
      line-height: 1.4;
    }
    &.biology {
      background-color: $white;
      background-image: none;
      .card-body {
        background-color: transparent;
      }
      .category {
        color: $biology;
        .icon {
          fill: $biology;
        }
      }
      .card-title a {
        color: $biology;
        .icon {
          fill: $biology;
        }
      }
      .flag-icon {
        background: $biology;
      }
      .icon {
        fill: $biology;
      }
      .card-body a,
      .card-body a h5.card-title {
        color: $biology;
        text-decoration: none;
      }
      .card-body .card-text {
        color: $card-p-color;
      }
      &::before {
        background: $biology;
      }
    }

    &.standard {
      background-color: $white;
      background-image: none;

      .card-body {
        background-color: transparent;
      }

      .card-body a,
      .card-body a h5.card-title {
        text-decoration: none;
      }

      .card-body .card-text {
        color: $card-p-color;
      }
    }

    &.service {
      background-color: $white;
      background-image: none;

      .card-body {
        background-color: transparent;
      }

      .flag-icon {
        background: #C2CED7;
      }

      .card-body a,
      .card-body a h5.card-title {
        color: #727272;
        text-decoration: none;
      }

      .card-body .card-text {
        color: $card-p-color;
      }

      .icon {
        fill: #727272;
      }

      &::before {
        background: #C2CED7;
      }
    }

    &.staff {
      background-color: $white;
      background-image: none;

      .card-body {
        background-color: transparent;
      }

      .flag-icon {
        background: #F2BA19;
      }

      .card-body a,
      .card-body a h5.card-title {
        color: #C39308;
        text-decoration: none;
      }

      .card-body .card-text {
        color: $card-p-color;
      }

      .icon {
        fill: #C39308;
      }

      &::before {
        background: #F2BA19;
      }
    }

    &.genomic {
      background-color: $white;
      background-image: none;
      .card-body {
        background-color: transparent;
      }
      .flag-icon {
        background: $genomic;
      }
      .card-body a,
      .card-body a h5.card-title {
        color: $genomic;
        text-decoration: none;
      }
      .card-body .card-text {
        color: $card-p-color;
      }
      .icon {
        fill: $genomic;
      }
      &::before {
        background: $genomic;
      }
    }

    &.molecular {
      background-color: $white;
      background-image: none;
      .card-body {
        background-color: transparent;
      }
      .flag-icon {
        background: $molecular;
      }
      .icon {
        fill: $molecular;
      }
      .card-body a,
      .card-body a h5.card-title {
        color: $molecular;
        text-decoration: none;
      }
      .card-body .card-text {
        color: $card-p-color;
      }
      &::before {
        background: $molecular;
      }
    }

  }

} // fine card

.number-card {
  .card {
    transition: transform .3s ease;

    .categoryicon-top {
      justify-content: center;

      .icon {
        width: 64px; 
        height: 64px; 
        fill: $italia;
      }
    }

    .card-title,
    .card-text {
      text-align: center;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}


.flag-icon {
  width: $flag-icon-w;
  height: $flag-icon-h;
  box-sizing: content-box;
  position: relative;
  background: $flag-icon-color;
  color: $card-shadow-bg;
  text-align: center;
  text-transform: uppercase;
}

.flag-icon:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 13px solid $card-shadow-bg;
  border-left: $flag-icon-w/2 solid transparent;
  border-right: $flag-icon-w/2 solid transparent;
}

.etichetta {
  display: flex;
  align-items: center;
  font-size: $card-category-size;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: $card-category-l-spacing;
  color: $card-p-color;
  .icon {
    fill: $card-p-color;
    width: $v-gap * 3;
    height: $v-gap * 3;
    margin-right: $v-gap;
    flex-shrink: 0;
  }
}

.row {
  [class*='col-'] {
    > .card-wrapper {
      height:  auto;
      > .card {
        height: 100%;
      }
    }
  }
}

.card.vertical-card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
  clear: both;
  .img-flex {
    height: auto;
    overflow: hidden;
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    margin-right: 2%;
    padding: 0;
    img {
      height: auto;
      width: 100%;
    }
    + .card-body {
      width: 100%;
      margin: 2% 0 0;
      padding-right: 0;
    }
  }
}


.it-carousel-wrapper {
  .card-activities-wrapper {
    padding-bottom: 0;
    .card {
      box-shadow: none;
      border: none;
      margin: 0;
      .card-body {
        padding: 15px;
        padding-left: 0;
        padding-right: 20px;
      }
    }
  }
  .see-all-btn {
    text-align: center;
    margin-top: 20px;
  }
}

.mask-img {
  width: 95px;
  height: 95px;
  margin: 10px 0 10px 0;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 95px;
  -webkit-mask-size: 95px;
}

#content .card-body a {
  text-decoration: none;
}

.section-progetti .card {
  background-color: transparent;
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .card-wrapper {
    padding-bottom: 10px;
    &.card-space {
      padding-bottom: $card-padding/2;
    }
  }
  .card {
    .card-body {
      .card-text {
        font-size: 0.9rem;
        line-height: 1.3;
      }
    } 
    &-hero {
      background-color: rgba(21, 95, 167, 0.61);
      margin-top: 0;
    }
  }
  .activity {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  #novita_evidenza {
    .card.vertical-card {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      box-shadow: none;
      border: none;
      .img-flex {
        max-height: 400px;
        height: auto;
        overflow: hidden;
        border-radius: 0;
        width: 40%;
        max-width: 40%;
        margin-right: 2%;
        padding: 0;
        img {
          height: 100%;
          width: auto;
        }
        + .card-body {
          width: 55%;
          margin: 2% 0 0;
          padding-right: 3%;
        }
      }
    }
  }
}


.twitter {
  display: flex;
  flex-wrap: nowrap;

  .card-wrapper {
    width: 30vw;
  }

  .twitter-card {
    background-color: transparent;
    box-shadow: none;

    blockquote.twitter-tweet {
      width: 100% !important;
    }
    
  }

}

.section-homepage .card-text iframe {
  display: none;
}

.phd-programs .card-wrapper {
  display: flex;
  align-items: center;
  margin-top: 2em;
}

.core-facilities {
  .card-body {
    a {
      text-align: center;
      display: block;
      width: 100%;
      font-weight: 600;
      text-decoration: none;
      font-size: 1.4em;
      line-height: 1.4;
      padding-bottom: 10px;
    }
  }
  .img-responsive-wrapper {
    padding: 2em 1em 1em;
    text-align: center;
    height: 150px;

    img {
      max-height: 107px;
      width: 100px;
    }
  }

  .full-image .img-responsive-wrapper {
    padding: 0;
    text-align: center;
    height: auto;

    img {
      max-height: 400px;
      width: 100%;
    }
  }
}

.core-facilities .card-body a {
  font-size: 1.4em;
}


.work {

  .card-wrapper {
    margin-bottom: 1.5em;
  }
  .researcher {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .flag-icon {
      order: 0;
      align-self: flex-start;
      margin-left: 27px;
    }

    .etichetta {
      order: 1;
      position: relative;
      top: auto;
      margin-top: 0.5em;
      margin-left: 1em;
    }

    .avatar {
      order: 2;
      text-align: center;
      margin: 1em auto;

      img {
        margin: 0 auto;
      }
    }

    .card-body {
      order: 3;
      padding: 1em;
      
      .card-text {
        font-size: 16px;
      }
      .phone {
        font-size: 14px;
        margin-bottom: 5px;
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .role {
        font-size: 14px;
        margin-bottom: 5px;
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .email {
        font-size: 14px;
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }
}


@media (max-width: #{map-get($grid-breakpoints, lg)}) {
  .twitter {
    flex-direction: column;

    .card-wrapper {
      width: 100%;
    }
  }

  .core-facilities .card-body a {
    font-size: 1em;
  }
  
}