.login {
  display: flex;
  align-items: center;
  color: $white;
  .btn-login {
    -moz-appearance: none;
    background-color: darken($secondary-color, 5%);
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $white;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
  
    &:hover {
      color: $white;
      background-color: darken($secondary-color, 10%);
      text-decoration: none;
      span {
        color: $white;
      }
      i, 
      .icon {
        color: $white;
        fill: $white;
        width: 20px;
        height: 20px;
      }
    }
    i,
    .icon {
      color: $white;
      fill: $white;
      width: 20px;
      height: 20px;
    }
    span {
      color: $white;
    }
  }
}


.btn {
  position: relative;
  border-radius: 100px;
  z-index: 1;
  min-width: 160px;
  height: 48px;
  line-height: 48px;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
  color: $white;
}

.btn-primary {
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-secondary {
  color: $white;
  background-color: $secondary-color;
  border: 1px solid $white;
}

.btn-transparent {
  border-radius: 100px;
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
  font-size: 1.02em;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.9rem;
  &:hover {
    background-color: $light-grey;
  }
  img {
    margin-right: 3px;
  }
}

.btn.grey-btn {
  border-radius: 100px;
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.2);
  display: block;
  width: 95%;
  margin-bottom: 1em;
  background-color: $light-grey;
  font-size: 1.02em;
  color: $primary;
  &:hover {
    background: darken($light-grey, 5%);
  }
}
.btn-outline-white {
  text-transform: uppercase;
  color: $white;
  border: 2px solid $white;
  display: inline-flex;
  align-items: center;
  align-content: center;
  font-size: 1.1rem;
  font-weight: 500;
  box-shadow: 0 20px 50px rgba(0,0,0,0.5);
  .icon {
    fill: $white;
    padding-right: 0.5em;
    align-self: flex-end;
  }
  &:hover {
    color: $white;
    background-color: rgba($white, 0.2);
  }
}

@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .btn-accedi span {
    display: inline-block;
    font-weight: 600;
  }
}
