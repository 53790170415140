.grey-callout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: $light-grey;
  padding: 1em;
  border-radius: 4px;
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin: 1em 0 1.5em;

  img {
    width: 45%;
    height: auto;
    margin: 20px 0;
    border-radius: 4px !important;
  }
}
