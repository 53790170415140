//mobile
.it-hero-wrapper {
  min-height: $hero-height-mob;
  position: relative;
  background-color: $white;
  display: flex;
  align-items: flex-end;
  // img -------------------------------------------
  .img-responsive-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    .img-responsive {
      padding-bottom: inherit;
      height: 100%;
      position: initial; 

      &.video {
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 300%;
        left: -100%;
        min-height: 56.25vw;

        iframe {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);
          pointer-events: none;
        }
      }
    }
  }
  // small size --------------------------------
  &.it-hero-small-size {
    min-height: $hero-height-sm-mob;
    .img-responsive-wrapper {
      .img-responsive {
      }
    }
  }
  // Text container ----------------------------
  .it-hero-text-wrapper {
    padding: $hero-text-padding * 2 $hero-text-padding + ($v-gap * 2)-6;
    background: transparent !important;
    position: relative;
    &.bg-dark {
      span, 
      h1 {
        position: relative;
        z-index: 2;
        background-color: rgba(1, 93, 181, 0.68);
        padding: 10px;
        border-radius: 3px;
      }
      
    }
    :last-child {
      margin-bottom: 0;
    }
    span,
    h1,
    p {
      color: $hero-text-color;
      margin-bottom: $v-gap * 3;
    }
    span.it-category {
      display: block;
      font-size: $hero-cat-size;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: $hero-cat-spacing;
    }
    h1 {
      font-size: $hero-heading-size;
      letter-spacing: unset;
    }
    p {
      font-size: $hero-p-size;
      font-family: $font-family-serif;
    }
    .it-btn-container {
      margin-top: 0;
    }
  }
  // Text container centered----------------------------
  &.it-text-centered {
    .it-hero-text-wrapper {
      text-align: center;
    }
  }
  // overlay color--------------------------------------
  &.it-overlay {
    .img-responsive-wrapper {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    // dark overlay
    &.it-dark {
      .img-responsive-wrapper {
        &:after {
          background-color: rgba(17, 113, 206, 0.82);
        }
      }
    }
    // dark overlay
    &.it-primary {
      .img-responsive-wrapper {
        &:after {
          background: $hero-primary-bg;
        }
      }
    }
    // filter
    &.it-filter {
      .img-responsive-wrapper {
        img {
          mix-blend-mode: screen;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  // negative bottom
  &.it-bottom-overlapping-content {
    margin-bottom: 0;
    max-height: 60vh;
    .it-hero-text-wrapper {
      padding-bottom: $hero-negative-bottom-padding-mob;
    }
  }

  &.it-wrapped-container {
    min-height: 0 !important; // disable prev min-height
    align-items: flex-end;
  }

  .it-hero-card {
    background-color: $white;

    &.it-hero-bottom-overlapping {
      margin-top: $hero-negative-margin;
      margin-bottom: -$hero-negative-margin;
    }
  }
}

// for mobile only
@media (max-width: #{map-get($grid-breakpoints, sm) - 1}) {
  .it-hero-wrapper {
    &.it-wrapped-container {
      display: flex;
      flex-direction: column;

      .container {
        background-color: $white;
      }

      .img-responsive-wrapper {
        position: relative;

        .img-wrapper {
          position: relative;
        }

        &:after {
          content: none;
        }
      }

      .it-hero-card {
        background-color: $white;
        border-radius: 0 !important;
        box-shadow: none !important;
      }
    }

    .it-hero-card {
      &.it-hero-bottom-overlapping {
        margin: 0;
      }
    }
  }
}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {
  .it-hero-wrapper {
    &.it-wrapped-container {
      min-height: 0 !important; // disable prev min-height
      align-items: flex-end;
    }

    .it-hero-card {
      background-color: $white;

      &.it-hero-bottom-overlapping {
        margin-top: $hero-negative-margin;
        margin-bottom: -$hero-negative-margin;
      }
    }
  }
}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {
  .it-hero-wrapper {
    // img small size --------------------------------
    &.it-hero-small-size {
      min-height: $hero-height-sm-tab;
    }
    // Text container ----------------------------
    .it-hero-text-wrapper {
      padding: $v-gap * 6 40% $v-gap * 6 ($v-gap * 5)-6;
    }
    // Text container centered----------------------------
    &.it-text-centered {
      .it-hero-text-wrapper {
        padding: $v-gap * 6 20% $v-gap * 6 20%;
      }
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {

  .hero {
    min-height: 600px;
    overflow: hidden;
    margin-bottom: 1em;
  }

  .it-hero-wrapper {
    position: relative;
    // min-height: $hero-height-desk;
    align-items: center;
    // img -------------------------------------------
    .img-responsive-wrapper {
      .img-responsive {
      }
    }
    // img small size --------------------------------
    &.it-hero-small-size {
      min-height: $hero-height-sm-desk;
      .img-responsive-wrapper {
        .img-responsive {
        }
      }
    }
    // Text container ----------------------------
    .it-hero-text-wrapper {
      padding: $hero-text-padding-desk 40% $hero-text-padding-desk +
        ($v-gap * 6) ($v-gap * 4)-6;
      span.it-category {
        position: absolute;
        margin-top: -$v-gap * 7;
      }
      h1 {
        font-size: $hero-heding-size-desk;
      }
      p {
        margin-bottom: 0;
      }
      .it-btn-container {
        position: absolute;
        margin-top: 0;
      }
    }
    // Text container centered----------------------------
    &.it-text-centered {
      .it-hero-text-wrapper {
        padding: $hero-text-padding-desk 20% $hero-text-padding-desk +
          ($v-gap * 3) 20%;
        span.it-category {
          left: 20%;
          right: 20%;
        }
        .it-btn-container {
          left: 20%;
          right: 20%;
        }
      }
    }
    // negative bottom
    &.it-bottom-overlapping-content {
      margin-bottom: -540px;
      min-height: $hero-height-desk;
      .it-hero-text-wrapper {
        padding-bottom: $hero-negative-bottom-padding;
        padding-top: $hero-text-padding-desk + $hero-negative-margin-desk/2;
      }
    }
  }
  .first {
    margin-top: 50px;
  }

}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {
  .it-hero-wrapper.it-bottom-overlapping-content {
    margin-bottom: -470px;
  }
  .first {
    margin-top: 87px;
  }
}

/* CSS ADDED FOR VIDEO */
@media (min-aspect-ratio: 16/9) {
  .img-responsive iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .img-responsive iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
  }
}
