.badge {
  display: inline-block;
  padding: .25em .6em;
  color: $green;
  border-radius: 10rem;
  border: 1px solid $green;
  margin-right: 8px;
  margin-bottom: 12px;
  font-size: .889em;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  &:hover {
    background-color: $green;
    color: #fff;
    text-decoration: none;
  }

  &-pill {
    color: $italia;
    border: 1px solid $italia;
    margin-right: 8px;
    margin-bottom: 12px;
    font-size: 0.889em;
    font-weight: 600;
    &:hover {
      background-color: $italia;
      color: $white;
      text-decoration: none;
    }
    &.badge-primary,
    &.badge-secondary,
    &.badge-success,
    &.badge-danger,
    &.badge-warning {
      color: $white;
      border: none;
    }
  }
}


