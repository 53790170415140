.sidebar-wrapper {
  padding: 24px 0;
  .sidebar-linklist-wrapper {
    .link-list-wrapper ul li {
      a span {
        color: $black;
      }
      a[data-toggle="collapse"].active {
        border-left: 2px solid $green;
        i,
        span {
          color: $green;
        }
      }
    }
  }
}
